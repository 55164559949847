import React from 'react';
import './style.css';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="faq">
          <h3>What is a secure ballot dropbox?</h3>
          <p>
          All across the Illinois 6th Congressional District, cities and towns are installing dropboxes where voters can return their completed ballots that they received in the mail. 
          If you requested and received a mail-in ballot, returning your ballot via a dropbox in your community is a safe and secure way to make sure that your vote is counted. 
          Please make sure to first complete your ballot (vote for Sean!) and then carefully follow all instructions provided with your ballot.
          </p>
        </div>
        <p className="footnote">
        We'll be updating this directory frequently. Please use this tool to search for the location of the ballot drop boxes in your community. 
        You can only drop off your ballot in a dropbox within the same county in which you reside. 
        Please be advised that you cannot drop off your ballot at a polling place, only at a secure dropbox or your local election office.
        </p>
        <p className="footnote">
        If you have more questions about voting, visit <a href="https://casten.us/vote">casten.us/vote</a>. And if you're interested in volunteering for Sean Casten's campaign, you can <a href="https://events.mobilizeamerica.io/embed/castenforcongress/signup">sign up</a> now!
        </p>
        
      </div>
      <span className="disclaimer">paid for by casten for congress</span>
    </footer>
  );
}
