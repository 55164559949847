import data from './data.json';

const locations = data.map((row) => ({
  id: row[0],
  splitIndicator: row[1],
  name: row[2],
  address: row[3],
  city: row[4],
  zip: row[5],
  openDate: row[6],
  closedDate: row[7],
  hours: row[8],
  moreinfo: row[9],
  countyClerkLocation: row[10],
  vbmAlertPhone: row[11],
  vbmAlertEmail: row[12],
  instructions: row[13],
  notYetAvailable: row[14],
}));

export const locationsByTown = locations.reduce((acc, location) => ({
  ...acc,
  [location.id]: location,
}), {});

export default locations;
