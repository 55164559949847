import React from 'react';
import copy from 'copy-to-clipboard';
import { useAppContext } from '../AppContext';
import Footer from '../Footer';
import './style.css';
import { ReactComponent as FacebookIcon } from './fb.svg';
import { ReactComponent as TwitterIcon } from './tw.svg';
import cover from './cover.jpg';

// FROM: https://stackoverflow.com/a/1500501
function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;

  return text.replace(urlRegex, function(url) {
    return '<a href="' + url + '">' + url + '</a>';
  });
}

export default function TownPage() {
  const { town } = useAppContext();

  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (copied) {
      const timeoutId = setTimeout(() => setCopied(false), 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [copied, setCopied]);

  if (!town) {
    return null;
  }

  const { id, splitIndicator, name, address, city, zip, openDate, closedDate, hours, countyClerkLocation, vbmAlertPhone, vbmAlertEmail, instructions, notYetAvailable } = town;
  const joinedAddress = `${address.trim()}${zip ? `, ${zip}` : ''}`;
  const mapAddress = `${address.trim()} ', ' ${city.trim()}', IL ' ${zip ? `, ${zip}` : ''}`;

  const joinedAddressLowercase = joinedAddress.toLowerCase();
  const hideMaps = joinedAddressLowercase.includes('location 1') ||
    joinedAddressLowercase.includes('multiple location') ||
    joinedAddressLowercase.includes('•') ||
    joinedAddressLowercase.includes('.;') ||
    joinedAddressLowercase.includes('contact your town clerk');

  const splitTown = `${splitIndicator}`.includes('true');

  const noDropBoxYet = `${notYetAvailable}`.includes('true');

  const shareLink = window.location.href;
  const customShareText = `How drop off your ballot in ${id}`;

  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${shareLink}&quote=${encodeURIComponent(customShareText || '')}`;
  const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(`${customShareText || ''}\n${shareLink}`)}`;

  function onCopy() {
    if (!copied) {
      copy(shareLink);
      setCopied(true);
    }
  }

  return (
    <React.Fragment>
      <div className="town">
        <h1 className="town-title">
          how to drop off your ballot in
          <span>{id}</span>
        </h1>

        {splitTown &&
        <div className="info-group">
          <h2>make sure you've selected the right county!</h2>
        </div>
        }

        {!noDropBoxYet && ( 
        <div className="info-group">
          <h3>nearest dropbox location</h3>
          <p>{name}</p>
          <p>{address}</p>
          <p>{city}, IL {zip}</p>

          {!hideMaps && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(mapAddress)}`}
            >Google Maps Directions</a>
          )}

          <h3>dropbox availability</h3>
          <p>{openDate} - {closedDate}</p>
          <p>{hours}</p>

            <h3>dropbox instructions</h3>
            <p dangerouslySetInnerHTML={{ __html: urlify(instructions) }} />
        </div>
        )}
         
        {noDropBoxYet && ( 
          <div className="info-group">
            <h2>Unfortunately, there are no drop box locations open yet for your city or town.</h2>
            <p>
            However, several drop box locations will be open as early as <strong>{openDate}</strong> for your city or town. 
            Please check back after that date for more information, or consult
            <a href="https://www.cookcountyclerk.com/service/mail-ballot-drop-box-locations"> the Cook County Election Board</a> for updated drop box site information.
            </p>
          </div>
        )}

        <div className="disclaimer">
          <p>
          NOTE: On Election Day, November 3rd, please follow the instructions of the Election Judges at your polling place 
          to insure that your Vote-By-Mail ballot is handled correctly, regardless of whether you have already voted that ballot
          or have not yet filled it out. There are also specific rules they must follow if you had requested a Vote-By-Mail 
          ballot, but never received it, so that you may still vote.
          </p>
        </div>

        <div className="info-group">
          <h3>vote-by-mail contact information for your county</h3>
          <p>
          Should you encounter any difficulties while attempting to deliver your Vote-By-Mail ballot 
          to a drop box facility, do not hesitate to contact your county election board representatives, 
          either by e-mail ({vbmAlertEmail}), telephone ({vbmAlertPhone}), or in person at the county offices ({countyClerkLocation}).
          </p>
        </div>

            
        <div className="info-group">
          <h3>share this with your friends & family</h3>
          <p>Let's insure everyone you know is able to safely vote early for Sean Casten.</p>
          <div className="share">
            <a target="_blank" rel="noopener noreferrer" href={facebookLink}>
              <FacebookIcon />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={twitterLink}>
              <TwitterIcon />
            </a>
            <button className="share-copy-button" onClick={onCopy}>
              {copied ? 'copied to clipboard!' : 'copy link'}
            </button>
          </div>
        </div>
          <img className="town-cover" src={cover} alt="Sean Casten casting his ballot at a polling site" />
        </div>
      <Footer />
    </React.Fragment>
  );
}
